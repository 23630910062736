<template>
  <div class="px-1 pt-2">
    <header class="header">
      <template v-for="(item, i) in infoCards">
        <div class="w-100" :key="i" @click="setActiveCard(i)">
          <card-data :active="activeCard == i" :info="item" />
        </div>
      </template>
    </header>
    <CardModal
      :type="activeCard"
      :date="date"
      v-if="showCardModal"
      @close="showCardModal = false"
    />
  </div>
</template>

<script>
import DashboardService from "@/views/quality/views/dashboard/services/dashboard.service.js";
import CardData from "@/views/quality/views/dashboard/components/CardData.vue";
import CardModal from "@/views/quality/views/dashboard/modals/CardModal.vue";
export default {
  props: {
    date: {
      type: String,
      default: null,
    },
  },
  components: {
    CardData,
    CardModal,
  },
  data() {
    return {
      activeCard: 0,
      infoCards: [],
      showCardModal: false,
    };
  },
  created() {
    this.getCardForClaims();
  },
  methods: {
    async getCardForClaims() {
      const params = {
        date: this.date,
      };
      const response = await DashboardService.getCardForClaims(params);
      this.infoCards = response.data;
    },
    setActiveCard(index) {
      this.activeCard = index;
      this.showCardModal = true;
    },
  },
  watch: {
    date() {
      this.getCardForClaims();
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(calc(21rem * var(--scale)), 1fr)
  );
  grid-gap: 1rem;
  justify-items: center;
}
</style>