<template>
  <div style="margin-top: 4.5rem">
    <b-table striped hover :items="items">
      <template #cell(status)="row">
        <b-badge :variant="colorText(row.value)" class="text-capitalize">
          {{ row.value }}
        </b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { status: "SOLVED", total: 20 },
        { status: "PENDING", total: 40 },
        { status: "SCALED", total: 30 },
        { status: "REACTIVED", total: 10 },
        { status: "NEW CLAIM", total: 100 },
      ],
    };
  },
  methods: {
    colorText(row) {
      if (row === "SOLVED") {
        return "light-success";
      } else if (row === "PENDING") {
        return "light-warning";
      } else if (row === "SCALED") {
        return "light-danger";
      } else if (row === "REACTIVED") {
        return "light-info";
      } else {
        return "light-primary";
      }
    },
  },
};
</script>