<template>
  <b-modal
    ref="modalInfoCard"
    size="lg"
    :title="`Clients in ${modalTitle}`"
    title-tag="h3"
    hide-footer
    modal-class="modal-primary"
    scrollable
    @hidden="closeModal"
  >
    <b-tabs
      v-model="tabIndex"
      pills
      lazy
      nav-class="mt-1"
      active-nav-item-class="bg-primary box-shadow-info"
    >
      <b-tab
        :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
        title="PENDING"
        @click="reloadTable()"
      />
      <b-tab
        :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
        title="DONE"
        @click="reloadTable()"
      />
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="tab-claims"
      />
      <b-table
        id="tab-claims"
        ref="table-claims"
        :per-page="perPage"
        :current-page="currentPage"
        :items="myProvider"
        :fields="fields"
        show-empty
      >
        <template #cell(name)="{ item }">
          <span class="d-flex align-items-center" style="gap: 5px">
            <status-account :account="item" :text="false"></status-account>
            <router-link
              target="_blank"
              :to="{
                name: 'quality-client-dashboard',
                params: {
                  idClient: `${item.account_id}`,
                },
              }"
            >
              {{ item.client }}
            </router-link>
          </span>
        </template>
        <template #cell(creator_of_the_registry)="{ item }">
          <div>
            {{ item.creator_of_the_registry }}
          </div>
          {{ item.created_at | myGlobal }}
        </template>
      </b-table>
    </b-tabs>
  </b-modal>
</template>

<script>
import ClaimServiceLevel from "@/views/commons/components/claims_v2/services/claims-v2-level.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  components: {
    StatusAccount,
  },
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      tabIndex: 0,
      dataClaims: [],
    };
  },
  mounted() {
    this.toggleModal("modalInfoCard");
  },
  computed: {
    fields() {
      return [
        { key: "name", label: "Client" },
        {
          key: "account",
          label: "Account",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "program",
          label: "Program",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "creator_of_the_registry",
          label: "Requested By",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ];
    },
    modalTitle() {
      const titles = {
        0: "LEVEL 0",
        1: "LEVEL 1",
        2: "LEVEL 2",
        3: "LEVEL 3",
      };
      return titles[this.type];
    },
    stateLevel() {
      const idLevels = {
        0: 1,
        1: 2,
        2: 3,
        3: 4,
      };
      return idLevels[this.type];
    },
    stateClaim() {
      const idClaims = {
        0: "SCALED",
        1: "COMPLETED",
      };
      return idClaims[this.tabIndex];
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async myProvider(ctx) {
      try {
        let parametros = {
          tab: this.stateClaim,
          level_id: this.stateLevel,
          ...ctx,
        };
        let data = await ClaimServiceLevel.getClaimData(parametros);
        this.currentPage = data.current_page;
        this.perPage = data.per_page;
        this.totalRows = data.total;
        this.dataClaims = data.data;
      } catch (ex) {
        this.dataClaims = [];
      }
      return this.dataClaims;
    },
    reloadTable() {
      this.$refs["table-claims"].refresh();
    },
  },
};
</script>

