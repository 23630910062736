<template>
  <div>
    <header-slot> </header-slot>

    <b-card>
      <b-row class="mb-1 px-1">
        <b-col md="6" class="global-search">
          <b-input-group class="mr-1">
            <b-input-group-prepend>
              <b-button variant="outline-info"> GLOBAL SEARCH </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="searchText"
              placeholder="Search by Name, Account or the last 4 digits of Phone number"
              @keyup.enter="searchGlobal"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="searchGlobal">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col>
          <div class="text-center">
            <b-form-select
              v-model="paymentMonth"
              :options="optionMonths"
              @input="reloadInformation()"
            />
          </div>
        </b-col>
        <b-col>
          <div class="text-center">
            <b-form-select
              v-model="paymentYear"
              :options="optionYears"
              @input="reloadInformation()"
            />
          </div>
        </b-col>
      </b-row>
      <!-- Claims -->

      <Claims :date="dateData" />
      <hr class="mt-2" />
      <div class="d-flex justify-content-between align-items-center px-1">
        <span class="title-data w-100"> GRAPHIC CLAIMS OF MONTH </span>
        <b-form-select :options="levelOptions" v-model="levelId" class="w-30" />
      </div>
      <ChartClaims :date="dateData" :type="levelId" />
      <div class="col-6 mt-2" v-if="isCeo || isChief || isSupervisor">
        <DepartmentExpenses
          :showDate="true"
          :yearDash="paymentYear"
          :monthDash="paymentMonth"
        />
      </div>
    </b-card>
    <modal-global-search
      v-if="modalGlobalSearch"
      :search-text="searchText"
      :program-id="programId"
      :role-id="currentUser.role_id"
      @closeModal="closeGlobalSearch"
    />
  </div>
</template>

<script setup>
import { mapGetters } from "vuex";
import moment from "moment";
import Claims from "./views/Claims.vue";
import ChartClaims from "./views/ChartClaims.vue";
import InfoData from "./views/InfoData.vue";
import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";
import DepartmentExpenses from "@/views/commons/expenses/components/department/DepartmentExpenses.vue";

export default {
  components: {
    Claims,
    ChartClaims,
    InfoData,
    ModalGlobalSearch,
    DepartmentExpenses,
  },
  data() {
    return {
      levelId: 1,
      keyInfo: 1,
      fieldSearch: "",
      modalGlobalSearch: false,
      paymentMonth: moment().format("MM") * 1,
      paymentYear: moment().format("YYYY"),
      dateData: moment().format("YYYY-MM-01"),
      levelOptions: [
        { text: "Level 0", value: 1 },
        { text: "Level 1", value: 2 },
        { text: "Level 2", value: 3 },
        { text: "Level 3", value: 4 },
      ],
      optionMonths: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      programId: 3,
      searchText: "",
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    optionYears() {
      let years = [];
      let year = new Date().getFullYear();
      for (let i = 0; i < 5; i++) {
        years.push({ text: year - i, value: year - i });
      }
      return years;
    },
  },
  methods: {
    setLevel(level) {
      this.levelId = level;
    },
    reloadInformation() {
      const month = moment(this.paymentMonth, "MM").format("MM");
      this.dateData = `${this.paymentYear}-${month}-01`;
    },
    async searchGlobal() {
      this.modalGlobalSearch = true;
    },
    closeGlobalSearch() {
      this.modalGlobalSearch = false;
    },
  },
};
</script>

<style lang="scss" >
.item-tab {
  a {
    background-color: transparent !important;
  }
}

.data-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;
}

@media (max-width: 1400px) {
  .data-container {
    grid-template-columns: 1fr;
  }
}

.title-data {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-primary) !important;
  border-bottom: 1px solid var(--color-primary) !important;
}

@media (max-width: 767px) {
  .global-search {
    margin-bottom: 1rem;
  }
}

.w-30 {
  width: 30% !important;
}
</style>
