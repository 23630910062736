import { amgApi } from "@/service/axios"

class DashboardService {
  async getCardForClaims(params) {
    try {
      const data = await amgApi.get("quality/dashboard/get-card-for-claims", {
        params,
      })
      return data.data
    } catch (error) {
      console.log("Something went wrong on getCardForClaims:", error)
      throw error
    }
  }
  async getChartForClaims(params) {
    try {
      const data = await amgApi.get("quality/dashboard/get-chart-for-claims", {
        params,
      })
      return data.data
    } catch (error) {
      console.log("Something went wrong on getChartForClaims:", error)
      throw error
    }
  }
}

export default new DashboardService()
