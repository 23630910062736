<template>
  <div
    class="card-data"
    :class="{
      active: active,
      dark: isDarkSkin,
    }"
  >
    <div class="card-data__header">
      <div class="card-data__content">
        <div class="card-data__value">
          {{ info.totalClaims }}
          <span class="card-data__value__sub">CLAIMS</span>
        </div>
        <div class="card-data__title text-uppercase">{{ info.title }}</div>
      </div>
      <div class="card-data__icon" :class="{ active: active }">
        <feather-icon icon="ClockIcon" size="20" />
      </div>
    </div>

    <div class="card-data__footer">
      <div class="card-data__pending">
        Pending: <span class="font-strong"> {{ info.pendingClaims }} </span>
      </div>
      <div class="card-data__done">
        Done:
        <span class="font-strong"> {{ info.doneClaims }} </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-data__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  .card-data__pending {
    font-size: calc(2rem * var(--scale));
    font-weight: 500;
    color: var(--text-color);
  }
  .card-data__done {
    font-size: calc(2rem * var(--scale));
    font-weight: 500;
    color: var(--text-color);
  }
}

.card-data__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  .card-data__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .card-data__title {
      font-size: calc(1.4rem * var(--scale));
      font-weight: 600;
      color: var(--text-color);
    }
    .card-data__value {
      font-size: calc(2.4rem * var(--scale));
      font-weight: 700;
      color: var(--text-color);
      border-bottom: 1px solid;
      margin-bottom: 0.5rem;
      .card-data__value__sub {
        font-size: calc(1.2rem * var(--scale));
        font-weight: 500;
      }
    }
  }
  .card-data__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 10px;
    background-color: #272727;
    color: white;
    &.active {
      background-color: white;
      color: #0563bd;
    }
  }
}

.card-data {
  width: 100%;
  height: calc(14rem * var(--scale));
  border-radius: 1rem;
  box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  &.dark {
    background-color: #333333;
    box-shadow: 0 0 0.3rem 0 rgba(105, 105, 105, 0.2);
  }
  &:hover {
    background: linear-gradient(90deg, #0563bd 0%, #0097b7 79%);
    color: white;
  }
  &.active {
    background: linear-gradient(90deg, #0563bd 0%, #0097b7 79%);
    color: white;
  }
}

@media (max-width: 480px) {
  .card-data__footer {
    .card-data__pending {
      font-size: calc(1.6rem * var(--scale));
    }
    .card-data__done {
      font-size: calc(1.6rem * var(--scale));
    }
  }
}
</style>
