var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalInfoCard",attrs:{"size":"lg","title":("Clients in " + _vm.modalTitle),"title-tag":"h3","hide-footer":"","modal-class":"modal-primary","scrollable":""},on:{"hidden":_vm.closeModal}},[_c('b-tabs',{attrs:{"pills":"","lazy":"","nav-class":"mt-1","active-nav-item-class":"bg-primary box-shadow-info"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title-link-class":[_vm.bgTabsNavs, 'sub-tab px-3'],"title":"PENDING"},on:{"click":function($event){return _vm.reloadTable()}}}),_c('b-tab',{attrs:{"title-link-class":[_vm.bgTabsNavs, 'sub-tab px-3'],"title":"DONE"},on:{"click":function($event){return _vm.reloadTable()}}}),_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"tab-claims"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('b-table',{ref:"table-claims",attrs:{"id":"tab-claims","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.myProvider,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[_c('status-account',{attrs:{"account":item,"text":false}}),_c('router-link',{attrs:{"target":"_blank","to":{
              name: 'quality-client-dashboard',
              params: {
                idClient: ("" + (item.account_id)),
              },
            }}},[_vm._v(" "+_vm._s(item.client)+" ")])],1)]}},{key:"cell(creator_of_the_registry)",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.creator_of_the_registry)+" ")]),_vm._v(" "+_vm._s(_vm._f("myGlobal")(item.created_at))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }