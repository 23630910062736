<template>
  <div>
    <e-charts
      ref="line"
      auto-resize
      autoresize
      :options="dataLineChart"
      theme="theme-color"
    />
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import theme from "@/views/social-network/views/dashboard2/components/chard/theme.json";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import DashboardService from "@/views/quality/views/dashboard/services/dashboard.service.js";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    date: {
      type: String,
      default: null,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dataLineChart: {
        grid: {
          width: "98%",
          left: "15px",
          right: "15px",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            id: "insideX",
            xAxisIndex: 0,
            start: 95,
            end: 100,
            zoomOnMouseWheel: false,
            moveOnMouseMove: true,
            moveOnMouseWheel: true,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          bottom: 0,
        },
        xAxis: {
          boundaryGap: true,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
          splitLine: { show: true },
          stepSize: 50,
          height: 500,
        },
        series: [
          {
            data: [120, 132, 101, 134, 90, 230, 210],
            type: "line",
            smooth: true,
            color: "#ff0059",
            name: "Pending",
          },
          {
            data: [20, 182, 191, 234, 290, 330, 310],
            type: "line",
            smooth: true,
            color: "#173fbc",
            name: "Done",
          },
          {
            data: [20, 182, 191, 234, 290, 330, 310],
            type: "line",
            smooth: true,
            color: "#2b2b2b",
            name: "Scaled",
          },
          {
            data: [20, 182, 191, 234, 290, 330, 310],
            type: "line",
            smooth: true,
            color: "#4caf50",
            name: "Reactivated",
          },
        ],
      },
    };
  },
  created() {
    this.getChartForClaims();
  },
  methods: {
    async getChartForClaims() {
      const params = {
        date: this.date,
        level_id: this.type,
      };
      const { days } = await DashboardService.getChartForClaims(params);
      this.dataLineChart.xAxis.data = days.map((day) => day.day);
      this.dataLineChart.series[0].data = days.map((day) => day.pending);
      this.dataLineChart.series[1].data = days.map((day) => day.done);
      this.dataLineChart.series[2].data = days.map((day) => day.scaled);
      this.dataLineChart.series[3].data = days.map((day) => day.reactivated);
    },
  },
  watch: {
    type() {
      this.getChartForClaims();
    },
    date() {
      this.getChartForClaims();
    },
  },
};
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
